<template>
  <div class="card">
    <header class="card-header has-background-primary">
      <p class="card-header-title has-text-light is-uppercase">TAMBAH LOKASI</p>
    </header>
    <div class="card-content events-card">
      <b-field class="field-spacer"></b-field>
      <div class="notification is-info is-light">
        Pastikan lokasi yang akan diinputkan belum terdaftar di database
        aplikasi. Isi (minimal) tiga huruf awal nama lokasi di halaman sebelum
        ini untuk menampilkan database lokasi.
      </div>
      <b-field class="field-spacer"></b-field>

      <template v-if="errorMap.non_field_errors">
        <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
        <b-field class="field-spacer"></b-field>
      </template>

      <b-field
        label="Lokasi"
        custom-class="is-small"
        :type="{ 'is-danger': errorMap.nama }"
        :message="errorMap.nama"
      >
        <b-input
          v-model="lokasi.nama"
          required
          @input="validateInput('nama')"
        ></b-input>
      </b-field>

      <b-field
        label="Jenis Rawat"
        custom-class="is-small"
        :type="{ 'is-danger': errorMap.rawat }"
        :message="errorMap.rawat"
      >
        <b-select
          v-model="lokasi.rawat"
          placeholder="Pilih"
          expanded
          required
          @input="validateInput('rawat')"
        >
          <option value="jalan">Jalan</option>
          <option value="inap">Inap</option>
        </b-select>
      </b-field>

      <b-field
        label="Jenis Lokasi"
        custom-class="is-small"
        :type="{ 'is-danger': errorMap.jenis }"
        :message="errorMap.jenis"
      >
        <generic-select
          v-model="lokasi.jenis"
          apiPath="/perawatan/jenis-lokasi/"
          @input="validateInput('jenis')"
        ></generic-select>
      </b-field>

      <b-field class="field-spacer"></b-field>
      <div class="buttons">
        <b-button
          class="is-primary has-text-weight-semibold"
          @click.stop="cancel"
          >Batal</b-button
        >
        <b-button
          class="is-primary has-text-weight-semibold"
          :disabled="!isValid"
          @click.stop.prevent="saveObject"
          >Simpan</b-button
        >
      </div>
      <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { has } from "lodash";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Lokasi from "../models/lokasi.js";

export default {
  name: "LokasiCreate",
  mixins: [createUpdateMixin],
  components: {
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  data() {
    // created dipanggil lebih dulu dari created()
    this.lokasiMdl = new Lokasi();
    this.objectMdl = this.lokasiMdl; // alias yg digunakan di cuMixin
    return this.lokasiMdl.getObservables();
  },
  methods: {
    ...mapActions("aktivitas", ["setAktLokasi", "setKegiatanLokasi"]),
    validateInput(field) {
      this.lokasiMdl.validate(field);
    },
    onSaved(respData) {
      if (has(this.$route.params, "aktLokasi")) {
        this.$route.params.aktLokasi["lokasi"] = respData.id;
        this.$route.params.aktLokasi["edited"] = true;
      } else if (has(this.$route.params, "kegiatanLokasi")) {
        this.$route.params.kegiatanLokasi["lokasi"] = respData.id;
        this.$route.params.kegiatanLokasi["edited"] = true;
      }
      this.cancel();
    },
    cancel() {
      if (has(this.$route.params, "aktLokasi")) {
        let aktLokasi = this.$route.params.aktLokasi;
        this.setAktLokasi(aktLokasi);
      } else if (has(this.$route.params, "kegiatanLokasi")) {
        let kegiatan = this.$route.params.kegiatanLokasi;
        this.setKegiatanLokasi(kegiatan);
      }
      this.$router.go(-1);
    },
  },
  watch: {
    lokasi: {
      // perawatan berubah, update edited
      handler(newValue, oldValue) {
        if (!this.lokasiMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true,
    },
  },
};
</script>
