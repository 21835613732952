
import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "lokasi";
const modelPath = `/perawatan/${modelName}/`;

class Lokasi extends ModelBase {
  constructor() {
    let model = {
      id: null,
      nama: "",
      jenis: null,
    };
    let requiredFields = ["nama", "rawat", "jenis"];
    super(modelName, model, requiredFields, [""], modelPath);
  }
}

export default Lokasi;
